import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Gymnastic Day`}</strong></p>
    <p>{`20:00 EMOM:`}</p>
    <p>{`Odd minutes: 1-Pullup + 1-CTB Pullup + 1-Bar Muscle Up`}</p>
    <p>{`(RX+ do another pullup + ctb + muscle up without dropping from the bar)`}</p>
    <p>{`Even minutes: 2-Plyo Pushups (on 45`}{`#`}{` bumpers)(RX+ on 2 45`}{`#`}{` bumpers)`}</p>
    <p>{`+`}{` 2-Clapping Pushups + 2-Pushups`}</p>
    <p>{`then,`}</p>
    <p>{`8:00 amrap:`}</p>
    <p>{`8-Ring dips`}</p>
    <p>{`8-Bodyweight Rows (lying under racked barbell at hip height)`}</p>
    <p>{`24-Double Unders`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      